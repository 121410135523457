import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Container from "../../../reusecore/UI/Container";
import Img from "gatsby-image";
import SectionWrapper from "./news.style";

import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const getNews = graphql`
  query emirNews {
    listNews: allContentfulNews(
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "id" } }
    ) {
      nodes {
        titleNews
        slug: slugNews
        contentful_id
        node_locale
        image: imageNews {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const NewsList = ({ data }) => {
  const { listNews } = useStaticQuery(getNews);

  return (
    <SectionWrapper id="services">
      <Container width="1260px">
        {/* <div className="berita-title">BERITA</div> */}
        <div>
          {listNews.nodes.map((item) => {
            return (
              <React.Fragment>
                <Link to={item.slug} key={item.id}>
                  <Img
                    className="berita-image"
                    fluid={item.image.fluid}
                    style={{ marginTop: "35px" }}
                  />
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default NewsList;
